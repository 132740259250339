<template>
  <div :class="classesAsString">
    <div class="text-block" v-html="content"></div>
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  name: 'InputAnswers',
  props: {
    content: {
      default: () => {},
      type: [Object, String]
    },
    classes: {
      default: () => [],
      type: [Array]
    }
  },
  computed: {
    classesAsString() {
      return this.classes.join(' ')
    }
  }
}
</script>

<style lang="scss" scoped>
.text-block /deep/ {
  * {
    font-size: 1.6rem;
    line-height: 1.5;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-bottom: 2.4rem;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;

    li {
      display: list-item;
    }
  }

  h2 {
    font-size: 2.4rem;
    margin-bottom: 0;
  }
}
</style>
