<template>
  <div class="toggle__wrapper">
    <label class="toggle">
      <input
        class="toggle__input"
        type="checkbox"
        :checked="checked"
        :disabled="disabled"
        @change="$emit('change', $event.target.checked)"
      />
      <span
        :class="{
          toggle__slider: true,
          'toggle__slider--disabled': disabled
        }"
      ></span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'ConfettiToggle',
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
$toggle-height: 3.2rem;
$toggle-width: 6.2rem;
$toggle-border-size: 0.3rem;

$toggle-border-unchecked: #d4d5d1;
$toggle-border-checked: #d4d5d1;
$toggle-text-unchecked: #acafb6;
$toggle-text-checked: #f6f7fa;
$toggle-bg-checked: #ffffff;
$toggle-bg-unchecked: #ffffff;
$toggle-slider-unchecked: #f7f8f3;
$toggle-slider-checked: $color-primary;
$toggle-border-slider-unchecked: #d4d5d1;
$toggle-border-slider-checked: $color-primary;
$toggle-sider-border: #d4d5d1;

.toggle {
  position: relative;
  display: inline-block;
  width: $toggle-width;
  height: $toggle-height;

  &__input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $toggle-bg-unchecked;
    transition: 0.4s;
    border-radius: $toggle-height;
    border: $toggle-border-size solid $toggle-border-unchecked;

    &--disabled {
      opacity: 0.5;
    }

    &::before {
      position: absolute;
      content: '';
      height: $toggle-height;
      width: $toggle-height;
      left: -$toggle-border-size;
      bottom: -$toggle-border-size;
      background-color: $toggle-slider-unchecked;
      transition: 0.2s;
      border-radius: 100%;
      z-index: 1;
      border: 3px solid $toggle-border-slider-unchecked;

      input:checked + & {
        border: $toggle-border-size solid $toggle-border-slider-checked;
        left: calc(100% - #{$toggle-height} + #{$toggle-border-size});
        background-color: $toggle-slider-checked;
      }
    }

    input:checked + & {
      border: $toggle-border-size solid $toggle-border-checked;
      background-color: $toggle-bg-checked;
    }
  }

  &__wrapper {
    display: inline-block;
  }
}
</style>
