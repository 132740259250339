<template>
  <div>
    <video
      ref="video"
      :src="content"
      class="video"
      :controls="isPlaying"
      :poster="poster"
      @click="playVideo"
      @pause="isPlaying = false"
    />
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  name: 'InputAnswers',
  props: {
    content: {
      default: () => {},
      type: [Object, String]
    },
    poster: {
      default: null,
      type: String
    }
  },
  data() {
    return {
      isPlaying: false
    }
  },
  methods: {
    playVideo() {
      if (!this.isPlaying) {
        this.$refs['video'].play()
        this.isPlaying = true
      }
    }
  }
}
</script>

<style>
.video {
  width: calc(100% + 40px);
  margin: auto -20px;
}
</style>
