<template>
  <div>
    <ImageItem :src="content" class="level__image" />
  </div>
</template>
<script>
import _ from 'lodash'
export default {
  name: 'ImageBlock',
  props: {
    content: {
      default: () => {},
      type: [Object, String]
    }
  }
}
</script>

<style></style>
