<template>
  <div class="page">
    <div
      class="page-header"
      :style="{ backgroundImage: `url(${headerImage})` }"
    ></div>

    <Content>
      <div class="challenge__header">
        <h2 class="challenge__sub-heading">{{ challenge.type }}</h2>
        <h1 class="challenge__heading">{{ challenge.name }}</h1>

        <div class="stats">
          <div class="stats__item">
            <Pill type="primary">{{ challenge.points }}pts</Pill>
          </div>
          <div class="stats__item">
            <template v-if="challenge.ends != 0">
              <i class="fal fa-alarm-clock stats__icon" />
              Ends: {{ formattedEndDate }}
            </template>
            <template v-else>
              <i class="fal fa-alarm-clock stats__icon" />
              Coming soon
            </template>
          </div>
          <div class="stats__item">
            <Pill type="tertiary" class="stats__button">
              £{{ challenge.savings }}+
            </Pill>
            savings
          </div>
          <div class="stats__item">
            <Pill type="quaternary" class="stats__button">
              {{ challenge.co2 }} tCO2
            </Pill>
            reduction
          </div>
        </div>
      </div>

      <template v-if="challengeState === 'completed'">
        <Content background="#F7F8F3" class="switch-content success open">
          <h3 class="success__heading">Challenge complete!</h3>
          <p class="success__points">
            You earned <Pill type="primary">{{ challenge.points }}pts!</Pill>
          </p>
          <p class="success__text">
            Your points have been added to<br />your personal and team totals
          </p>
        </Content>
      </template>

      <template v-else-if="challengeState === 'confirm_switch'">
        <Content background="#F7F8F3" class="switch-content">
          <h3 class="switch-content__heading">Complete the challenge</h3>
          <p class="switch-content__text">
            Please confirm you have made the swtich and your points will be
            added to the leaderboard.
            <a href="#">Access the energy switch link here</a>
          </p>
          <label class="switch-toggle">
            <Toggle v-model="confirmSwitch" /> I confirm I have made the switch
          </label>
          <div class="switch-content__button-group button-group">
            <Button
              type="success"
              :disabled="isStartSwitchDisabled"
              @click.native="handleCompleteChallenge"
              >Complete</Button
            >
            <Button type="transparent">I've already switched / unable</Button>
          </div>
        </Content>
      </template>

      <template v-else-if="challengeState === 'already_switched'">
        <Content background="#F7F8F3" class="switch-content">
          <h3 class="switch-content__heading">Already switched?</h3>
          <p class="switch-content__text">
            Please let us know if you have already switched or are unable to do
            so. If we can, we’ll add your score to the leaderboard.
          </p>
          <SelectInput
            :options="alreadySwitchedOptions"
            class="switch-reasons"
          />
          <div class="switch-content__button-group button-group">
            <Button type="success" @click.native="challengeState = 'completed'"
              >Submit</Button
            >
            <Button type="transparent" @click.native="challengeState = ''"
              >Cancel and return to challenge</Button
            >
          </div>
        </Content>
      </template>

      <template v-else-if="challengeState === 'started'">
        <Content background="#F7F8F3" class="switch-content">
          <h3 class="switch-content__heading">Make the switch</h3>
          <p class="switch-content__text">
            Use the link below to switch your energy supplier. Once you’re all
            setup, pop back here and mark it as complete.
          </p>
          <div class="switch-content__button-group button-group">
            <Button
              type="success"
              @click.native="challengeState = 'confirm_switch'"
              >Start your switch</Button
            >
            <Button
              type="transparent"
              @click.native="challengeState = 'already_switched'"
              >I've already switched / unable</Button
            >
          </div>
        </Content>
      </template>

      <template v-else>
        <div class="challenge__content">
          <div
            v-for="contentBlock in challengeContentBlocks"
            :key="contentBlock.id"
          >
            <component
              :is="contentBlock.type"
              :content="contentBlock.content"
              :classes="contentBlock.classes"
            ></component>
          </div>

          <div class="challenge__button-group button-group">
            <Button
              v-if="challenge.ends != 0"
              type="success"
              @click.native="handleJoinChallenge"
              >Join the challenge</Button
            >
            <Button v-else type="success" disabled="true"
              >Join the challenge</Button
            >
            <Button
              type="transparent"
              @click.native="challengeState = 'already_switched'"
              >I've already switched / unable</Button
            >
          </div>
        </div>
      </template>

      <Leaderboard :challenge-state="challengeState" />
    </Content>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Leaderboard from '@/components/global/Leaderboard'
import Toggle from '@/components/global/ui/Toggle'
import TextBlock from '../../components/global/content-blocks/TextBlock.vue'
import ImageBlock from '../../components/global/content-blocks/ImageBlock.vue'
import VideoBlock from '../../components/global/content-blocks/VideoBlock.vue'

export default {
  components: { Leaderboard, Toggle, TextBlock, VideoBlock, ImageBlock },
  data: function() {
    return {
      challengeId: null,
      confirmSwitch: false,
      challengeState: null,
      alreadySwitchedOptions: [
        {
          text: "I've already switched"
        },

        {
          text: 'I am unable to switch'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.user.currentUser
    }),
    ...mapGetters({
      challenge: 'challenge/getChallenge',
      challengeContentBlocks:
        'challengeContentBlocks/getChallengeContentBlocks',
      isChallengeCompleted: 'challenge/isChallengeCompleted'
    }),
    formattedEndDate() {
      return this.$luxon(this.challenge.ends, {
        input: {
          format: 'seconds'
        },
        output: {
          format: 'EEE d MMMM'
        }
      })
    },
    isStartSwitchDisabled() {
      return !this.confirmSwitch
    },
    isCompleted() {
      return this.currentUser.completedChallenges.includes(this.challengeId)
    },
    headerImage() {
      return this.$mq === 'sm'
        ? this.challenge.headerImagePath
        : this.challenge.headerImagePathDesktop
    }
  },
  watch: {
    isCompleted: function(newValue) {
      if (newValue) {
        this.challengeState = 'completed'
      }
    }
  },
  mounted() {
    this.challengeId = this.$route.params.challengeId
    this.$store.dispatch('challenge/openDBChannel', {
      pathVariables: {
        challengeId: this.challengeId
      }
    })
    this.$store.dispatch('challengeContentBlocks/openDBChannel', {
      pathVariables: {
        challengeId: this.challengeId
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('challenge/closeDBChannel', {
      clearModule: true
    })

    this.$store.dispatch('challengeContentBlocks/closeDBChannel', {
      clearModule: true
    })
    next()
  },
  methods: {
    ...mapActions({
      completeChallenge: 'user/completeChallenge',
      startChallenge: 'user/startChallenge'
    }),
    handleJoinChallenge() {
      this.challengeState = 'started'
      this.startChallenge(this.challenge.id)
    },
    handleCompleteChallenge() {
      this.challengeState = 'completed'
      this.completeChallenge(this.challenge.id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/components/stats';
@import '@/assets/sass/components/page-header';

.switch-toggle {
  display: flex;
  margin-bottom: 1.6rem;
  align-items: center;
  font-size: 1.6rem;

  .toggle__wrapper /deep/ {
    margin-right: 1.6rem;
  }
}

.challenge__header {
  margin-bottom: 3.2rem;
}

.challenge__sub-heading {
  font-size: 1.6rem;
}

.challenge__heading {
  font-size: 3.2rem;
}

.button-group > * {
  margin-bottom: 1.8rem;
}

.challenge__button-group {
  margin-top: 2.4rem;
  margin-bottom: 4.8rem;
}

.switch-content {
  width: calc(100% + 40px); //temporary hack, dont judge
  margin: auto -20px 2rem;
  max-width: none;
  padding-top: 3rem;
  padding-bottom: 3rem;

  &__heading {
    font-size: 24px;
    line-height: 29px;
    font-weight: 400;
    margin-bottom: 1.6rem;
  }

  &__text {
    font-size: 16px;
    line-height: 19px;
  }
}

.success {
  @extend .confetti-background;
  text-align: center;
  background-color: #f7f8f3;
  padding: 5.3rem 1.5rem 2.8rem;
  opacity: 0;
  transition: 0.25s ease all;

  &.open {
    top: 0 !important;
    opacity: 1;
  }

  &__heading {
    font-size: 3rem;
    color: $color-gray--dark;
    font-weight: 600;
  }

  &__points {
    color: #54c6cc;
    font-size: 1.8rem;
    font-weight: 500;
  }

  &__text {
    font-size: 1.6rem;
    color: $color-gray--dark;
  }
}

.switch-reasons {
  margin-bottom: 4.2rem;
}

/deep/ .level__image {
  width: 60%;
  margin: 16px auto;
}
</style>
