<template>
  <div>
    <h3 class="styled-table__heading">Current standings</h3>
    <table class="styled-table">
      <thead>
        <tr>
          <th></th>
          <th>Team</th>
          <th>Points</th>
        </tr>
      </thead>
      <tbody is="transition-group" name="teams-table">
        <tr
          v-for="team in orderedTeams"
          :key="team.name"
          :class="team.active ? 'active-row' : ''"
        >
          <td>
            <span
              v-show="team.movement === 'up'"
              :class="team.movement === 'up' ? 'active' : ''"
              class="up"
            ></span>
            <span
              v-show="team.movement === 'down'"
              :class="team.movement === 'down' ? 'active' : ''"
              class="down"
            ></span>
          </td>
          <td>{{ team.name }}</td>
          <td>{{ team.points }}</td>
        </tr>
      </tbody>
    </table>
    <div class="buttons__container">
      <!-- <Button :on-click="completeChallenge">Simulate Complete Challenge</Button>

      <Button type="transparent" :on-click="reset">Reset</Button> -->
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  components: {},
  props: {
    challengeState: {
      type: String,
      default: null
    }
  },
  data: function() {
    this.defaultTeams = [
      {
        name: 'Design team',
        points: 9000,
        active: false,
        movement: null
      },
      {
        name: 'Development team',
        points: 8090,
        active: true
      },
      {
        name: 'Procurement',
        points: 1440,
        active: false,
        movement: null
      },

      {
        name: 'Marketing FR',
        points: 1210,
        active: false,
        movement: null
      },
      {
        name: 'Marketing UK',
        points: 1200,
        active: false,
        movement: null
      },
      {
        name: 'HR',
        points: 800,
        active: false,
        movement: null
      }
    ]
    return {
      teams: _.cloneDeep(this.defaultTeams)
    }
  },
  computed: {
    orderedTeams() {
      const newOrder = [...this.teams].sort((a, b) => b.points - a.points)
      newOrder.forEach((team, index) => {
        const oldIndex = this.teams.findIndex(t => t.name === team.name)
        if (index < oldIndex) {
          team.movement = 'up'
        } else if (index > oldIndex) {
          team.movement = 'down'
        }
      })
      return newOrder
    }
  },
  watch: {
    challengeState: {
      immediate: true,
      handler(val) {
        if (val === 'completed') {
          this.completeChallenge()
        }
      }
    }
  },
  methods: {
    completeChallenge() {
      // const teams = [...this.teams]
      this.teams.find(team => team.name === 'Development team').points = 9001
      // this.teams = teams
    },
    reset() {
      this.teams = _.cloneDeep(this.defaultTeams)
      this.challengeState = null
    }
  }
}
</script>
<style lang="scss" scoped>
.teams-table-move {
  transition: transform 1s;
}

.styled-table {
  border-collapse: collapse;
  font-size: 1.6rem;
  min-width: 400px;
  width: calc(100% + 40px); //temporary hack, dont judge
  margin: auto -20px 2rem;
  color: $color-black;
}

.styled-table thead tr {
  background-color: #eaece2;
  color: $color-black;
  text-align: left;
  font-weight: 600;
  border-bottom: 2px solid #979797;

  td:first-child() {
    padding-left: 0;
  }
}

.styled-table th,
.styled-table td {
  position: relative;
  padding: 1.6rem 2rem;

  .up,
  .down {
    opacity: 0;
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent $color-success transparent;
    transition: 1s ease-in-out opacity;

    &.active {
      opacity: 1;
    }
  }

  &:first-of-type {
    width: 32px;
    padding: 1.6rem 0 1.6rem 1.6rem;
  }

  .down {
    border-width: 8px 8px 0 8px;
    border-color: $color-error transparent transparent transparent;
  }
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f7f8f3;
}

.styled-table tbody tr.active-row {
  background-color: $color-primary;
  font-weight: bold;
  color: #ffffff;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #979797;
}

.buttons__container {
  display: flex;
  margin-bottom: 1.2rem;

  button {
    margin: 0 2px;
  }
}

.styled-table__heading {
  font-size: 24px;
  font-weight: normal;
  color: $color-black;
  line-height: 1.3;
}
</style>
